import React from 'react'
import Layout from '../components/layout'
import contactStyles from './contact.module.scss'
import Head from '../components/head'

import linkedinImage from '../resources/linkedinLogo.png'
import gmailImage from '../resources/gmailLogo.png'
import itchImage from '../resources/itch.io.png'
const ContactPage = () =>{
    return(
        <Layout>
            <Head title="Contact"/>
            <div className={contactStyles.mainContent}>
                <h2>Contact me @</h2>
                <div className={contactStyles.verticalAlign}>
                    <img src={linkedinImage} className={contactStyles.gmailImage}/>
                     <a className={contactStyles.alignMiddle} href ="https://www.linkedin.com/in/hiteshmittapelly/">LinkedIn</a>
                     </div>
                <div className={contactStyles.verticalAlign}>
                    <img src={gmailImage} className={contactStyles.gmailImage}/> 
                    <a className={contactStyles.alignMiddle} href="mailto:hitheshwar.m@gmail.com">Gmail-hitheshwar.m@gmail.com</a> 
                    </div>
                <div className={contactStyles.verticalAlign}>
                    <img src={itchImage} className={contactStyles.itchImage}/> 
                    <a className={contactStyles.alignMiddle} href ="https://hitesh.itch.io/">Itch.io</a>
                    </div>

            </div>
            
        </Layout>
    )
}

export default ContactPage